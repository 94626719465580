import State from 'state';
// import matches from '../helpers/matches';
import closest from '../helpers/closest';
import each from '../helpers/each';
import findKey from '../helpers/findKey';
import values from '../helpers/values';
import uniq from '../helpers/uniq';
import clone from '../helpers/clone';
import pluck from '../helpers/pluck';


export class Goal {

  // static GOAL_IDS_ATTR = 'data-s3-goal-ids';
  static GOAL_ANKETA_ID_ATTR = 'data-s3-anketa-id';
  static GOAL_API_FORM_URL_ATTR = 'data-api-url';
  static logEnabled = false;


  /**
   * @constructor
   * @param {Object} params
   * @param {Object<Object>} params.map Объект с целями при отправке формы
   * @param {Array<Object>} params.ecommerce Данные электронной коммерции для отправки
   * @param {Function} params.afterSend Функция, которая выполняется после отправки ecommerce
   * @param {Array<Object>} params.goals Массив целей, которые нужно отправить в соответствии с ключами
   */
  constructor(params) {
    Goal.map = params.map || {};
    Goal.ecommerce = params.ecommerce || [];
    Goal.afterSend = params.afterSend || null;

    Ecommerce.init(Goal.ecommerce);

    Goal.bindEvents();

    Goal.observe(node => {
      if (node.tagName !== 'IFRAME') {
        return;
      }
      try {
        const contentWindow = node.contentWindow;
        if (!contentWindow || !contentWindow.addEventListener) {
          return;
        }
        contentWindow.addEventListener('DOMContentLoaded', () => {
          if (contentWindow.s3 && contentWindow.s3.Goal) {
            return;
          }
          Goal.bindEvents(contentWindow.document);
        });
      } catch (e) {
      }
    });

    Goal.sendGoals(params.goals);
  }

  static sendGoals(goals) {
    if (!goals.length) {
      return;
    }

    let yandexSended = false;
    let googleSended = false;
    let sberADSSended = false;
    const isEnd = yandexSended && googleSended && sberADSSended;
    let iteration = 0;

    const interval = setInterval(() => {
      if (Goal.yandexCounters.length && !yandexSended) {
        yandexSended = true;
        each(goals, (goal) => {
          if(goal.metrika) {
            Goal.yandexSend({label: goal.metrika});
          }
        });
      }

      if (Goal.googleCounters.length && !googleSended) {
        googleSended = true;
        each(goals, (goal) => {
          if(goal.analytics) {
            Goal.googleSend({label: goal.analytics});
          }
        });
      }

      if (Goal.sberADSCounters.length && !sberADSSended) {
        sberADSSended = true;
        each(goals, (goal) => {
          if(goal.sber_ads) {
            Goal.sberSend({label: goal.sber_ads});
          }
        });
      }

      iteration++;

      if (iteration >= 10 || isEnd) {
        clearInterval(interval);
      }
    }, 500);
  }

  static bindEvents(context = document) {
    const events = pluck(Goal.map, 'event');
    each(events, name => {
      context.addEventListener(name, e => {
        // e.preventDefault();
        Goal.handle(e.target, name);
      }, true);
    });
  }


  static handle(node, eventName) {
    // пока обрабатываем только submit форм
    if (node.tagName === 'FORM') {
      let anketa_id = node.getAttribute(Goal.GOAL_ANKETA_ID_ATTR);

      if (!anketa_id) {
        const apiFormWrap = closest(node, `[${Goal.GOAL_API_FORM_URL_ATTR}]`);
        if (apiFormWrap) {
          anketa_id = Goal.getApiFormId(apiFormWrap);
        }
      }

      if (!anketa_id) {
        return;
      }

      // сюда обработку апишных форм дописать
      each(Goal.map, item => {
        if (item.object_id == anketa_id && item.event == eventName && (item.code == 'anketa' || item.code == 'anketa2')) {
          item.object = 'form';
          Goal.send(item);
        }
      });
      return;
    }

    // а тут будет все остальное
  }

  static send(params) {
    switch (params.system) {
      case 'analytics':
        Goal.googleSend(params);
        break;
      case 'metrika':
        Goal.yandexSend(params);
        break;
      case 'sber_ads':
        Goal.sberSend(params);
        break;
    }
  }


  static get googleCounters() {
    if (window.ga) {
      return [window.ga];
    }
    return [];
  }


  static get yandexCounters() {
    try {
      return values(Ya._metrika.counters);
    } catch (e) {
      if (Goal.logEnabled) {
        console.error(e);
      }
      return [];
    }
  }

  static get sberADSCounters() {
    try {
      if(window.top100Counter){
        return [window.top100Counter];
      }
      return [];
    } catch (e) {
      if (Goal.logEnabled) {
        console.error(e);
      }
      return [];
    }
  }

  static get existCounters() {
    return this.yandexCounters.length || this.googleCounters.length || this.sberADSCounters.length;
  }


  static googleSend(params) {
    const counters = Goal.googleCounters;
    each(counters, counter => {
      // counter('send', 'event', params.object, params.event, params.label);
      counter(() => {
        const trackers = counter.getAll();
        trackers.forEach((tracker) => {
          const name = tracker.get('name');
          counter(name + '.send', 'event', params.object, params.event, params.label);
        });
      });
      if (Goal.logEnabled) {
        console.info('Goal.send', params);
      }
    });
    return true;
  }


  static yandexSend(params) {
    const counters = Goal.yandexCounters;
    if (!counters) {
      return false;
    }
    each(counters, counter => {
      counter.reachGoal(params.label, {object: params.object, event: params.event});
      if (Goal.logEnabled) {
        console.info('Goal.send', params);
      }
    });
    return true;
  }

  static sberSend(params) {
    const counters = Goal.sberADSCounters;
    if (!counters.length) {
      return false;
    }
    const counter = counters[0];
    counter.trackEvent(params.label, {object: params.object, event: params.event});
    if (Goal.logEnabled) {
      console.info('Goal.send', params);
    }
    return true;
  }


  static get googleDataLayerKeys() {
    if (!window.google_tag_manager) {
      return [];
    }
    const key = findKey(google_tag_manager, (item) => item.gtmDom && item.gtmLoad);
    if (key) {
      return [key];
    }
    return [];
  }


  static get yandexDataLayerKeys() {
    const counters = Goal.yandexCounters;
    const keys = [];
    each(counters, counter => {
      if (counter._ecommerce) {
        keys.push(counter._ecommerce);
      }
    });
    return keys;
  }


  static observe(fn) {
    if (!window.MutationObserver) {
      return;
    }
    const observer = new MutationObserver(function (mutations) {
      mutations.forEach(function (mutation) {
        [].forEach.call(mutation.addedNodes, function (node) {
          if (node.nodeType == Node.ELEMENT_NODE) {
            fn(node);
          }
        });
      });
    });
    observer.observe(document, {childList: true, subtree: true});
  }


  static getApiFormId(node) {
    const url = node.getAttribute(Goal.GOAL_API_FORM_URL_ATTR);
    if (!url) {
      return null;
    }
    const matches = url.match(/(?:&|\?)param\[form_id\]=(\d+)(?:&|$)/);
    if (!matches || matches.length !== 2) {
      return null;
    }
    return matches[1];
  }

}


export class Ecommerce {

  static state = new State('s3.goal.ecommerce');
  static _pool = [];
  static logEnabled = false;
  static _defaultKeys = ['dataLayer'];
  static isSend = false;

  static init(pool) {
    pool.forEach((item) => {
      let order_id = null;
      try {
        order_id = item.ecommerce.purchase.actionField.id;
      } catch (e) {
      }
      if (order_id) {
        Ecommerce.state.delete('products');
        const orders = Ecommerce.state.get('orders') || {};
        if (orders[order_id]) {
          return;
        }
        orders[order_id] = 1;
        Ecommerce.state.set('orders', orders);
      }
      if (item._correction) {
        Ecommerce.recount(item._correction);
        // Ecommerce.state.delete('recount');
        return;
      }
      Ecommerce.push(item);
    });

    setInterval(() => {
      Ecommerce.send(); // знаю что плохо, но невозможно определить в какой момент на странице появится счетчик (и сколько их всего)
    }, 100);

    if (!window.jQuery) {
      return;
    }

    jQuery.ajaxSetup({
      beforeSend: function (deferred, settings) {
        const matches = settings.url.match(/(?:\?|&)cmd=(cartAddItem|cartRemoveItem|cartUpdate)(?:&|$)/);
        if (!matches || matches.length !== 2) {
          return;
        }
        const method = matches[1];
        switch (method) {
          case 'cartAddItem':
            deferred.done(function (json) {
              if (typeof json == 'object' && json.ecommerce) {
                Ecommerce.addProduct(json.ecommerce);
              }
            });
            break;
          /*case 'cartRemoveItem':
           case 'cartUpdate':
           Ecommerce.state.set('recount', true);
           break;*/
        }
      }
    });
  }

  static push(value) {
    ['add', 'remove', 'detail', 'purchase'].some(function (action) {
      if (value.ecommerce[action]) {
        value.event = 's3_shop_' + action;
        return true;
      }
    });
    Ecommerce._pool.push({value, layers: {}});
    Ecommerce.send();
  }


  static send() {
    let keys = uniq([...Goal.googleDataLayerKeys.concat(Goal.yandexDataLayerKeys), ...Ecommerce._defaultKeys]);
    each(keys, key => {
      const layer = window[key] = window[key] || [];
      each(Ecommerce._pool, item => {
        if (!item.layers[key]) {
          const value = clone(item.value);
          layer.push(value);
          if (Ecommerce.logEnabled) {
            console.info('Ecommerce.send', key, value);
          }
          item.layers[key] = true;
          Ecommerce.isSend = true;
        }
      });
    });
    if (Goal.afterSend && typeof Goal.afterSend === 'function' && Ecommerce.isSend) {
      Goal.afterSend();
      Ecommerce.isSend = false;
    }
  }


  static addProduct(items) {
    const hash = Ecommerce.state.get('products') || {};
    items.forEach(item => {
      item.ecommerce.add.products.forEach(product => {
        const key = Ecommerce.getKeyProduct(product);
        if (!hash[key]) {
          hash[key] = product;
          return;
        }
        hash[key].quantity += product.quantity;
      });
      Ecommerce.push(item);
    });
    Ecommerce.state.set('products', hash);
  }


  static getKeyProduct(product) {
    return JSON.stringify([product.id + '', product.variant]);
  }


  static recount(correction) {
    const cart = {};
    each(correction, (item) => {
      cart[Ecommerce.getKeyProduct(item)] = item;
    });

    const store = Ecommerce.state.get('products') || {};

    each(cart, (item, key) => {
      if (!store[key]) {
        store[key] = item;
        Ecommerce.add(item);
        return;
      }
      const diff = cart[key].quantity - store[key].quantity;

      if (diff < 0) {
        item.quantity = -diff;
        Ecommerce.remove(item);
        store[key].quantity += diff;
      } else if (diff > 0) {
        item.quantity = diff;
        Ecommerce.add(item);
        store[key].quantity += diff;
      }
    });

    each(store, (item, key) => {
      if (!cart[key]) {
        Ecommerce.remove(item);
        delete store[key];
      }
    });

    Ecommerce.state.set('products', store);
  }


  static remove(product) {
    Ecommerce.push({ecommerce: {remove: {products: [product]}}});
  }


  static add(product, count) {
    Ecommerce.push({ecommerce: {add: {products: [product]}}});
  }

}


if (__DEBUG__) {
  Goal.logEnabled = true;
  Ecommerce.logEnabled = true;
}